import { render, staticRenderFns } from "./index.vue?vue&type=template&id=04a4c7c1&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=04a4c7c1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a4c7c1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PopupBanner: require('/usr/src/app/src/components/base/containers/PopupBanner.vue').default,HomeJumbotron: require('/usr/src/app/src/components/home/containers/HomeJumbotron.vue').default,HomeMenu: require('/usr/src/app/src/components/home/containers/HomeMenu.vue').default,HomeFeature: require('/usr/src/app/src/components/home/containers/HomeFeature.vue').default,HomeServices: require('/usr/src/app/src/components/home/containers/HomeServices.vue').default,HomeNews: require('/usr/src/app/src/components/home/containers/HomeNews.vue').default})
