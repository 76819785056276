
import { mdiClose } from '@mdi/js'

export default {
  setup() {
    return {
      mdiClose,
    }
  },
  data: () => ({
    homeBannerData: [],
    isLoading: true,
    itemKey: 'home.banner',
  }),
  computed: {
    openDialog() {
      return this.$store.getters['general/getPopupBannerDialog']
    },
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    closeDialog() {
      this.$store.commit('general/SET_POPUP_BANNER_DIALOG', false)
    },
    async fetchData() {
      this.isLoading = true
      const res = await this.$services.webSetting.getItemKey({
        itemKey: this.itemKey,
      })
      if (res.success) {
        this.homeBannerData = res.data
      } else {
        this.homeBannerData = []
      }
      this.isLoading = false
    },
  },
}
